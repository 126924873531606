export const AUTH_TOKEN = window.location.host + '/AUTH_TOKEN';

export const getUserSnippet = username => {
  let initials = '';
  if (!username) {
    return initials;
  }

  const fullname = username.split(' ');
  const initialLetters = fullname.map(name => name.substring(0, 1));
  [initials] = initialLetters;
  if (initialLetters[1]) {
    initials  += initialLetters[1];
  }

  return initials.toUpperCase();
}

export const subsidiaryTypes = [
  'Affiliate Member', 'Branch Office', 'Consumer Relations', 'Country Headquarters',
  'Distribution Center', 'Head Office', 'Headquarters', 'Joint Venture',
  'Manufacturing Facility', 'Production Facility', 'R & D Center', 'Regional Headquarters',
  'Representative Office', 'Retail Location', 'Sales Office', 'Service Centers'];

export const gmapsApiKey = 'AIzaSyBzCC2DktRuOoBtvHD3w1p8wIygDHTVyBY'

// Add this state name to code mapping
export const stateNameToCode = {
  'Alabama': 'AL',
  'Alaska': 'AK',
  'Arizona': 'AZ',
  'Arkansas': 'AR',
  'California': 'CA',
  'Colorado': 'CO',
  'Connecticut': 'CT',
  'Delaware': 'DE',
  'Florida': 'FL',
  'Georgia': 'GA',
  'Hawaii': 'HI',
  'Idaho': 'ID',
  'Illinois': 'IL',
  'Indiana': 'IN',
  'Iowa': 'IA',
  'Kansas': 'KS',
  'Kentucky': 'KY',
  'Louisiana': 'LA',
  'Maine': 'ME',
  'Maryland': 'MD',
  'Massachusetts': 'MA',
  'Michigan': 'MI',
  'Minnesota': 'MN',
  'Mississippi': 'MS',
  'Missouri': 'MO',
  'Montana': 'MT',
  'Nebraska': 'NE',
  'Nevada': 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Ohio': 'OH',
  'Oklahoma': 'OK',
  'Oregon': 'OR',
  'Pennsylvania': 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  'Tennessee': 'TN',
  'Texas': 'TX',
  'Utah': 'UT',
  'Vermont': 'VT',
  'Virginia': 'VA',
  'Washington': 'WA',
  'West Virginia': 'WV',
  'Wisconsin': 'WI',
  'Wyoming': 'WY'
};

export const listOfTitles = [
"",
"Account Dir.",
"Account Exec.",
"Acct. Mgr.",
"Acting CEO",
"Acting CFO",
"Acting Pres.",
"Admin.",
"Advisor",
"Agent",
"Architect",
"Area Gen. Mgr.",
"Area Sales Mgr.",
"Assoc.",
"Asst.",
"Attorney",
"AVP",
"Branch Dir.",
"Branch Mgr.",
"Brand Head",
"Bus. Dev. Mgr.",
"Business Analyst",
"Business Dir.",
"CAO",
"CCO",
"CEO",
"CEO & CFO",
"CEO & COO",
"CEO & CTO",
"CEO & Dir.",
"CEO & Gen. Mgr.",
"CEO & Mng. Dir.",
"CEO & Mng. Prtn.",
"CEO & Vice Chmn.",
"CEO Americas",
"CEO APAC",
"CEO Asia",
"CEO Australia and New Zealand",
"CEO Eastern Europe",
"CEO EMEA",
"CEO Europe",
"CEO France",
"CEO Global",
"CEO India",
"CEO Intl.",
"CEO Latin America",
"CEO North America",
"CEO Sales",
"CEO UK",
"CEO USA",
"CFO",
"CFO & CIO",
"CFO & COO",
"CFO & EVP",
"CFO & HR",
"CFO & HR VP",
"CFO & Mng. Dir.",
"CFO & SVP",
"CFO & VP Finance",
"CFO & VP Operations",
"CFO Americas",
"CFO Europe",
"CFO North America",
"CFO Worldwide",
"CFO, EVP",
"CFO, VP Opers.",
"Chair",
"Chair & CEO",
"Chair, Exec. Cmte.",
"Chief Acct.",
"Chief Business Officer",
"Chief Commercial Officer",
"Chief Compliance Officer",
"Chief Creative Officer",
"Chief Digital Officer",
"Chief Economist",
"Chief Growth Officer",
"Chief HR Officer",
"Chief Investment Officer",
"Chief IT Officer",
"Chief Learning Officer",
"Chief Learning Oficerf",
"Chief of Staff",
"Chief People Officer",
"Chief Product Officer",
"Chief Representative",
"Chief Revenue Officer",
"Chief Sales Officer",
"Chief Scientific Officer",
"Chief Strategy Officer",
"Chief Talent Officer",
"Chmn.",
"Chmn. & CEO",
"Chmn. & Co-CEO",
"Chmn. & Exec. Dir.",
"Chmn. & Founder",
"Chmn. & GM",
"Chmn. & Mng. Dir.",
"Chmn. & Mng. Prtn.",
"Chmn. & Pres.",
"Chmn. & Pres. & CEO",
"Chmn. & Prtn.",
"Chmn. & Sr. Prtn.",
"Chmn. Americas",
"Chmn. Intl.",
"Chmn. Mgmt. Cmte.",
"Chmn. USA",
"CIO",
"Client Manager",
"CMO",
"Co-CEO",
"Co-Chmn. & CEO",
"Co-Founder",
"Co-Founder & CEO",
"Co-Founder & CTO",
"Co-Founder & EVP",
"Co-Founder & Pres.",
"Co-Founder & Prtn.",
"Co-Founder & SVP",
"Co-Founder & VP",
"Co-Managing Prtn.",
"Commercial Dir.",
"Commercial Mgr.",
"Communications Dir.",
"Company Secretary",
"Consultant",
"Consumer Relations",
"Contracts Mgr.",
"Controller",
"COO",
"COO & CFO",
"COO & CTO",
"COO & EVP",
"COO & Mktg. Dir.",
"COO & Mng. Dir.",
"COO & SVP",
"COO Americas",
"COO Asia",
"COO Europe",
"COO Global",
"COO Int'l",
"COO US",
"COO/CFO",
"Corporate Relations",
"Counsel",
"Country Head",
"Country Mgr.",
"CPO - Chief Product Officer",
"Creative Dir.",
"CSO",
"CTO",
"CTO & Co-Founder",
"CTO & GM",
"CTOO",
"Cust. Service",
"Cust. Service Mgr.",
"Cust. Support",
"Customer Communications",
"Customer Service Mgr.",
"Customer Support",
"Customer Support Mgr",
"Dealer Development",
"Dept. Mgr.",
"Deputy CEO",
"Deputy CEO & CFO",
"Deputy CEO Finance",
"Deputy Chmn.",
"Deputy Chmn. & CFO",
"Deputy Chmn. & Mng. Dir.",
"Deputy Dir.",
"Deputy Dir. General",
"Deputy Gen. Mgr.",
"Deputy GM",
"Deputy GM Sales & Mktg.",
"Deputy Gov.",
"Deputy Mgr.",
"Deputy Mng. Dir.",
"Deputy Pres.",
"Deputy Pres. & COO",
"Deputy Pres. Finance",
"Deputy Sales Mgr.",
"Deputy VP",
"Design",
"Design Dir.",
"Dev. Engineer",
"Development & Production",
"Development Mgr.",
"Digital Mktg. Coordinator",
"Dir.",
"Dir. & Admin.",
"Dir. & CEO",
"Dir. & CFO",
"Dir. & COO",
"Dir. & CTO",
"Dir. & VP",
"Dir. & VP HR",
"Dir. Admin.",
"Dir. Admin. & Fin.",
"Dir. Admin. & HR",
"Dir. Americas",
"Dir. Asia",
"Dir. Asia Pacific",
"Dir. Broadcast Production",
"Dir. Bus. Dev.",
"Dir. Bus. Operations",
"Dir. Communications",
"Dir. Corp. Comms.",
"Dir. Corp. Svc.",
"Dir. Distribution",
"Dir. EMEA",
"Dir. Engineering",
"Dir. Europe",
"Dir. European Operations",
"Dir. European Sales/Mktg.",
"Dir. Fin. & Admin.",
"Dir. Finance",
"Dir. Finance & Admin.",
"Dir. Finance & Operations",
"Dir. Gen.",
"Dir. Global",
"Dir. Global Business Dev.",
"Dir. Global Mktg.",
"Dir. Global Sales",
"Dir. Grp. Sales",
"Dir. HR",
"Dir. Intl.",
"Dir. Intl. Sales",
"Dir. IR",
"Dir. IT",
"Dir. Media",
"Dir. Mfg.",
"Dir. Mktg.",
"Dir. Mktg. & Communications",
"Dir. Mktg. & Sales",
"Dir. Mktg. Comm.",
"Dir. of Education",
"Dir. of Mktg.",
"Dir. of Operations",
"Dir. of Sales",
"Dir. Operations",
"Dir. Personnel",
"Dir. PR",
"Dir. Product Development",
"Dir. Product Mgmt.",
"Dir. R&D",
"Dir. Recruiting",
"Dir. Sales",
"Dir. Sales & Mktg.",
"Dir. Sales & Operations",
"Dir. Strategic Planning",
"Dir. Strategy",
"Dir. US Operations",
"Dir. Worldwide Sales",
"Director Business Dev.",
"Director of Operations",
"Dist. Mgr.",
"Dist. Sales",
"Dist. Sales Mgr.",
"Distribution Mgr.",
"District Mgr.",
"District Sales Mgr.",
"Div. CEO",
"Div. Dir.",
"Div. Mgr.",
"Div. Pres.",
"Div. VP",
"Divisional  Mng. Dir.",
"DSP",
"e-commerce Mgr.",
"Eastern Reg. Sales Mgr.",
"ED",
"Editor",
"Editor-in-Chief",
"EMEA CEO",
"EMEA Sales Dir.",
"Engineer",
"Engineer Mgr.",
"Engineering",
"Engineering Mgr.",
"Equipment Mgr.",
"Esq., Legal",
"Estimator",
"ESVP Sales",
"EVP",
"EVP & CAO",
"EVP & CEO",
"EVP & CFO",
"EVP & Chief HR",
"EVP & CIO",
"EVP & CMO",
"EVP & Co-CEO",
"EVP & Co-Founder",
"EVP & COO",
"EVP & CTO",
"EVP & Gen. Mgr.",
"EVP & Gen. Mgr. Asia",
"EVP & GM",
"EVP & Mng. Dir",
"EVP & Pres.",
"EVP & Secy",
"EVP & Treasurer",
"EVP - CFO",
"EVP Admin.",
"EVP Africa",
"EVP Americas",
"EVP Asia",
"EVP Asia Pacific",
"EVP Bus. Dev.",
"EVP Cargo",
"EVP CFO",
"EVP Communications",
"EVP COO",
"EVP Corp. Dev.",
"EVP Dev. & Technology",
"EVP Development",
"EVP East Region",
"EVP EMEA",
"EVP Europe",
"EVP Fin.",
"EVP Finance",
"EVP Finance and Operations",
"EVP Global",
"EVP Global HR",
"EVP Global Operations",
"EVP Global Ops.",
"EVP Global Sales",
"EVP Global Trade",
"EVP HR",
"EVP Int'l",
"EVP Investments",
"EVP Middle East",
"EVP Mktg.",
"EVP Mktg. & Sales",
"EVP Mktg. & Sales & Other stuff",
"EVP Mktg./Sales",
"EVP North America",
"EVP Operations",
"EVP Operations & Fin.",
"EVP Opers.",
"EVP R&D",
"EVP Sales",
"EVP Sales & Mktg.",
"EVP Worldwide",
"EVP Worldwide Sales",
"EVP, CFO",
"EVP-COO",
"Exec Comm Dir",
"Exec. Asst.",
"Exec. Chmn.",
"Exec. Chmn., Operations",
"Exec. Deputy Pres.",
"Exec. Dir.",
"Exec. Dir. & CFO",
"Exec. Dir. & Gen. Mgr.",
"Exec. Dir. EMEA",
"Exec. Dir. Europe & Africa",
"Exec. Dir. Finance",
"Exec. Dir. HR",
"Exec. Dir. Operations",
"Exec. Dir. PR",
"Exec. Editor",
"Exec. Mgr.  Americas",
"Exec. Mgr. Operations",
"Exec. Mng. Dir.",
"Exec. Operating Officer",
"Exec. Producer",
"Exec. Prtn.",
"Executive Bus. Dev.",
"Executive Dir.",
"Executive Mng. Dir.",
"Executive Officer",
"Executive Producer",
"Expansion PR",
"Exploration Mgr.",
"Export",
"Export Dir.",
"Export Mgr.",
"Export Retail",
"Export Sales Dir.",
"Facilities Mgr.",
"Field Operations",
"Field Service Engineer",
"Field Services Mgr.",
"Fin.",
"Fin. & IR",
"Fin. Dir.",
"Finance, VP",
"Finance",
"Finance & Admin.",
"Finance & HR",
"Finance & IR",
"Finance Dir.",
"Finance Mgr.",
"Finance, HR & IT",
"Financial & HR Dir.",
"Financial Controller",
"Financial Dir.",
"First Deputy CEO",
"First VP",
"Founder",
"Founder & CEO",
"Founder & COO",
"Founder & CTO",
"Founder & Mng. Dir.",
"Founder & Pres.",
"Founder & SVP",
"Founding Partner",
"Founding Prtn.",
"Franchise Development",
"Franchisee",
"Fund Accountant",
"Gallery Dir.",
"Gen Mgr.",
"Gen. & Mktg. Mgr.",
"Gen. Counsel",
"Gen. Dir.",
"Gen. Inquiries",
"Gen. Mgr.",
"Gen. Mgr. & COO",
"Gen. Mgr. & Dir.",
"Gen. Mgr. Americas",
"Gen. Mgr. Asia",
"Gen. Mgr. Canada",
"Gen. Mgr. EMEA",
"Gen. Mgr. Europe",
"Gen. Mgr. HK",
"Gen. Mgr. HR",
"Gen. Mgr. Intl.",
"Gen. Mgr. Operations",
"Gen. Mgr. Sales",
"Gen. Prtn.",
"Gen. Prtn. & COO",
"Gen. Sales Mgr.",
"Geo Reg. Mgr.",
"Geographic Mgr.",
"Germany CEO",
"Global",
"Global Bus. Dev.",
"Global Bus. Dev. Dir.",
"Global CEO",
"Global CFO",
"Global Chief Strategy Officer",
"Global Commercial Dir.",
"Global COO",
"Global CTO",
"Global Customer Service",
"Global Dir.",
"Global Dir. Client Services",
"Global Dir. Gen. Aviation Services",
"Global Dir. HR",
"Global Dir. IT",
"Global Dir. Sales & Mktg.",
"Global Director",
"Global Finance and IT Dir.",
"Global Finance Dir.",
"Global Head of HR",
"Global Head of Sales",
"Global HR",
"Global HR Dir.",
"Global HR Mgr.",
"Global Human Resources",
"Global Mgr.",
"Global Mktg.",
"Global Mktg. Dir.",
"Global Mktg. Mgr.",
"Global Mkts.",
"Global Mng. Dir.",
"Global Mng. Prtn.",
"Global Operations",
"Global PR",
"Global Sales",
"Global Sales & Mktg.",
"Global Supply",
"Global Tech Dir.",
"Global VP Finance",
"Global VP Mktg.",
"GM",
"GM & CTO",
"GM & VP Board",
"GM America",
"GM Corp. Planning",
"GM Digital",
"GM EMEA",
"GM Europe",
"GM IR",
"GM Nordic Countries",
"GM North America",
"GM Operations",
"GM P&E",
"GM R&D",
"GM Retail",
"GM Revenue Management",
"GM Technology",
"GM USA",
"Gov't. Services Dir.",
"Gov.",
"Group CEO",
"Group CFO",
"Group CFO & COO",
"Group Co-CEO",
"Group Controller",
"Group COO",
"Group CTO",
"Group Dir. Americas",
"Group Dir. HR",
"Group EVP",
"Group EVP & COO",
"Group Fin. Dir.",
"Group Finance",
"Group Finance Director",
"Group Financial Controller",
"Group Financial Dir.",
"Group Gen. Mgr.",
"Group HR Dir.",
"Group HR Mgr.",
"Group Mktg. Mgr.",
"Group Mng. Dir.",
"Group Operating Officer",
"Group Operations",
"Group Operations Dir.",
"Group Pres.",
"Group Sales Dir.",
"Group Sales Mgr.",
"Group Technical Dir.",
"Group VP",
"Group VP Sales",
"Grp. Finance Dir.",
"Grp. HR Dir.",
"Grp. Product Mgr.",
"Grp. VP",
"Gulf Coast Reg. Mgr.",
"Head Intl. Bus.",
"Head of Acquisitions",
"Head of Americas",
"Head of Asia/Pacific",
"Head of Bus. Dev.",
"Head of China",
"Head of Content",
"Head of Corp. Comm.",
"Head of Direct Funds",
"Head of EMEA",
"Head of Equities",
"Head of Europe",
"Head of HR",
"Head of HR & Admin.",
"Head of Innovation",
"Head of Investment",
"Head of IR",
"Head of Latam & Africa",
"Head of Marketing",
"Head of North America",
"Head of Office",
"Head of Operations",
"Head of Personnel",
"Head of PR",
"Head of Process Technology",
"Head of Production",
"Head of Real Estate",
"Head of Research",
"Head of Sales",
"Head of Talent Mgmt.",
"Head of the Americas",
"Head of UK & Ireland",
"Home Div.",
"Hotel Mgr.",
"HR",
"HR & CFO",
"HR Asst.",
"HR Dir.",
"HR Director",
"HR Mgr.",
"HR North America",
"HRO",
"Import Mgr.",
"Import Sales Rep.",
"Imports",
"India Operations",
"Inside Sales",
"Inside Sales Dir and",
"Inside Sales Exec.",
"Inside Sales Mgr.",
"Institutional Sales",
"Int'l.",
"Int'l. Bus. Dev.",
"Int'l. Bus. Mgr.",
"Int'l. Fin. Dir.",
"Int'l. Marketing",
"Int'l. Mktg.",
"Int'l. Mktg. Dir.",
"Int'l. Opers.",
"Int'l. PR",
"Int'l. Sales",
"Int'l. Sales  & Mktg. Dir.",
"Int'l. Sales Dir.",
"Int'l. Sales Mgr.",
"Interim CEO",
"Interim CFO",
"Interim Pres.",
"Interim Secretary",
"Investment Mgr.",
"Investor Relations",
"IO",
"IR",
"IR Mgr.",
"IT",
"IT Communications",
"IT Director",
"IT Mgr.",
"IT Mgre.",
"IT Operations",
"IT Service & Support",
"IT Support Mgr.",
"Joint CEO, Chmn.",
"Joint Mng. Dir.",
"Key Account Mgr.",
"Lawyer",
"Leasing",
"Leasing Mgr.",
"Leasing Rep.",
"Legal",
"Legal Counsel",
"Life & Health",
"Life & Health Ins.",
"Life Ins.",
"Logistics",
"Logistics Mgr.",
"Maintenance",
"Maintenance Dir.",
"Maintenance Mgr.",
"Marketing",
"Marketing Specialist",
"MD",
"MD, CSO",
"MD, VP",
"Med. Dir.",
"Media & Mktg. Mgr.",
"Media Dir.",
"Media Relations",
"Media Relations Mgr.",
"Medical Dir.",
"Member",
"Mfg.",
"Mfg. & Purchasing",
"Mfg. Engineer",
"Mfg. Mgr.",
"Mfr. Operations Mgr.",
"Mgr.",
"Mgr. Americas",
"Mgr. APU Services",
"Mgr. Asia",
"Mgr. Bus. Dev.",
"Mgr. Canada",
"Mgr. Client Services",
"Mgr. Corp. Affairs",
"Mgr. Corp. Comm. & IR",
", CFO",
"Mgr. Eng.",
"Mgr. Engineering",
"Mgr. Europe",
"Mgr. Fin.",
"Mgr. Global Operations",
"Mgr. HR",
"Mgr. Intl.",
"Mgr. Leasing",
"Mgr. Media Relations",
"Mgr. Mktg.",
"Mgr. Operations",
"Mgr. PR",
"Mgr. Publications",
"Mgr. Sales",
"Mgr. Sales and Operations",
"Mgr. Tech Services",
"Mgr. Tech Support",
"Midwest Sales Mgr.",
"Military Sales",
"Mktg.",
"Mktg. & Admin.",
"Mktg. & Bus. Dev.",
"Mktg. & Communications",
"Mktg. & Communications Mgr.",
"Mktg. & PR",
"Mktg. & R&D",
"Mktg. & Sales",
"Mktg. & Sales Dir.",
"Mktg. Admin.",
"Mktg. Communications Mgr.",
"Mktg. Coordinator",
"Mktg. Dir.",
"Mktg. Dir. APAC",
"Mktg. Mgr",
"Mktg. Operations",
"Mktg. Promotions",
"Mktg. Services",
"Mng. Assoc.",
"Mng. Clinical Dev.",
"Mng. Dir.",
"Mng. Dir.  Finance",
"Mng. Dir. & CAO",
"Mng. Dir. & CFO",
"Mng. Dir. & Co-CEO",
"Mng. Dir. & Co-Founder",
"Mng. Dir. & COO",
"Mng. Dir. & GM",
"Mng. Dir. & SVP",
"Mng. Dir. & VP",
"Mng. Dir. Americas",
"Mng. Dir. APAC",
"Mng. Dir. Asia",
"Mng. Dir. Bus. Dev.",
"Mng. Dir. EMEA",
"Mng. Dir. Engineering",
"Mng. Dir. Europe",
"Mng. Dir. Finance",
"Mng. Dir. Finance & IT",
"Mng. Dir. Global",
"Mng. Dir. HR",
"Mng. Dir. India",
"Mng. Dir. Int'l.",
"Mng. Dir. Mktg.",
"Mng. Dir. N. America",
"Mng. Dir. North America",
"Mng. Dir. Operations",
"Mng. Dir. Sales",
"Mng. Dir. Shipping",
"Mng. Dir. UK",
"Mng. Dir. US",
"Mng. Dir. USA",
"Mng. Dir., Sales & Mktg.",
"Mng. Editor",
"Mng. EVP",
"Mng. Exec.",
"Mng. Exec. Officer",
"Mng. Executive Officer",
"Mng. Gen. Prtn.",
"Mng. HR",
"Mng. Member",
"Mng. Officer",
"Mng. Principal",
"Mng. Prtn.",
"Mng. Prtn. & CEO",
"Mng. Prtn. & CFO",
"Mng. Prtn. Asia",
"Mng. Prtn. EMEA",
"Mng. Prtn. Intl.",
"Mng. Prtn., Americas",
"Mng. Prtn., Asia-Pacific",
"Mng. Prtn., EMEA",
"Mng. Prtns.",
"Mng. Shareholder",
"Move Mgr.",
"Moving Consultant",
"NA Sales",
"Nat'l Sales Dir.",
"Nat'l Sales Mgr.",
"Nat'l. Acct. Mgr.",
"Nat'l. Reg. Mgr.",
"Nat'l. Sales Dir.",
"Nat'l. Sales Mgr.",
"Nat'l. Sales Rep.",
"Nat'l. Service Mgr.",
"National Acc't Mgr.",
"NE Reg. Mgr.",
"New Bus. Dev.",
"New Business",
"Northern Reg. Mgr.",
"Nutrition Mgr.",
"OEM & Mktg.",
"OEM Mgr.",
"OEM Sales",
"Of Counsel",
"Ofc. Admin.",
"Ofc. Mgr.",
"Office Admin.",
"Office Head",
"Office Mgr.",
"Officer",
"Operating Prtn.",
"Operations",
"Operations & Technology",
"Operations Controller",
"Operations Dir.",
"Operations Engineer",
"Operations Mgr.",
"Operations Prtn.",
"Operations, Sales & Mktg.",
"Outside Sales",
"Overseas Sales",
"Owner",
"Partner",
"Parts",
"Payroll",
"PhD, VP & CTO",
"Plant Engineer",
"Plant Mgr.",
"Plant Quality Mgr.",
"Plastics Div.",
"PR",
"PR & IR",
"PR Dir.",
"PR Manager of Marketing Dept.",
"PR Mgr.",
"PR, Mng. Dir",
"PR/IR Manager",
"Pres.",
"Pres.  Real Estate Financing",
"Pres. & CBO",
"Pres. & CEO",
"Pres. & CEO & COO",
"Pres. & CEO & Dir.",
"Pres. & CEO America",
"Pres. & CEO Americas",
"Pres. & CEO North America",
"Pres. & CEO Travel Group",
"Pres. & CEO US",
"Pres. & CFO",
"Pres. & Chmn.",
"Pres. & CIO",
"Pres. & Co-CEO",
"Pres. & Co-COO",
"Pres. & Co-Founder",
"Pres. & COO",
"Pres. & CTO",
"Pres. & Dir.",
"Pres. & Gen. Mgr.",
"Pres. & GM",
"Pres. & Mgr.",
"Pres. & Mng. Dir.",
"Pres. & Mng. Dir. China",
"Pres. & Pub.",
"Pres. Acer Inc.",
"Pres. Americas",
"Pres. APL",
"Pres. Asia",
"Pres. Asia Pacific",
"Pres. Canada",
"Pres. Canadian Operations",
"Pres. China",
"Pres. Commercial Aircraft",
"Pres. Commercial Banking",
"Pres. Dev. & Mfg.",
"Pres. Development",
"Pres. EMEA",
"Pres. Europe",
"Pres. Global",
"Pres. HR",
"Pres. India",
"Pres. Intl.",
"Pres. Intl. Operations",
"Pres. IT",
"Pres. Japan",
"Pres. Latin America",
"Pres. Media",
"Pres. Mktg.",
"Pres. NA",
"Pres. NA Operations",
"Pres. North America",
"Pres. Operations",
"Pres. Parts",
"Pres. PTFE Group",
"Pres. Real Estate Div.",
"Pres. Sales",
"Pres. Sales & Mktg.",
"Pres. Sales Trucks NA",
"Pres. US",
"Pres. US Operations",
"Pres. USA",
"Pres. Weston Foods",
"Pres. Worldwide",
"Pres. Yamato Transport Co., Ltd.",
"President Staffing",
"President US",
"Presiding Prtn.",
"Principal",
"Principal Mng. Dir.",
"Principal Officer",
"Principal Prtn.",
"Process Control",
"Procurement Controller",
"Procurement Mgr.",
"Prod. Mgr.",
"Product Development",
"Product Development Mgr.",
"Product Mgmt.",
"Product Mgr.",
"Product Mktg.",
"Product Mktg. Mgr.",
"Production",
"Production Mgr.",
"Program Mgr.",
"Proj. Exec.",
"Proj. Mgr.",
"Project Engineer",
"Project Mgmt.",
"Project Mgr.",
"Project Mgr. USA",
"Prtn.",
"Prtn. & CEO",
"Prtn. & CFO",
"Prtn. & COO",
"Prtn. & Head of Corporate Law",
"Prtn. & Mng. Dir.",
"Prtn. HR",
"Prtn. In Charge",
"Prtn. Int'l Practice",
"Prtn. Int'l.",
"Prtn., Co-Head Media",
"Pub.",
"Purchasing",
"Purchasing Mgr.",
"QA Mgr.",
"QC Manager",
"Quality Assurance",
"Quality Engineer",
"Quality Mgmt.",
"Quality Mgr.",
"Quality Supervisor",
"R&D",
"R&D Mgr.",
"Realty Div.",
"Recruiter",
"Recycling",
"Reg Dir. Asia Pacific",
"Reg. Acct. Mgr.",
"Reg. CEO",
"Reg. COO",
"Reg. Dir.",
"Reg. Dir. & SVP",
"Reg. Finance Dir.",
"Reg. HR Dir.",
"Reg. HR Mgr.",
"Reg. Mgr.",
"Reg. Mgr. Miami",
"Reg. Mgr. North America",
"Reg. Mgr. Sales",
"Reg. Mgr. US",
"Reg. Mng. Dir.",
"Reg. Mng. Prtn.",
"Reg. Pres.",
"Reg. Rep.",
"Reg. Sales",
"Reg. Sales & Mktg.",
"Reg. Sales Dir.",
"Reg. Sales Mgr.",
"Reg. Sales Rep.",
"Reg. Tech Sales",
"Reg. VP",
"Regional Coord.",
"Regulatory Super",
"Rentals & Service Mgr.",
"Rep.",
"Rep. Dir.",
"Res. Dir.",
"Research Associate",
"Risk Analyst",
"Sales",
"Sales & Bus. Dev.",
"Sales & Bus. Dev. Dir.",
"Sales & Design",
"Sales & Marketing",
"Sales & Mktg.",
"Sales & Mktg. Dir",
"Sales & Mktg. Mgr.",
"Sales & Service Dir.",
"Sales Admin.",
"Sales and Operations",
"Sales Assistant",
"Sales Asst.",
"Sales Chicago",
"Sales Coordinator",
"Sales Dir.",
"Sales Dir. EMEA",
"Sales Dir. Europe",
"Sales Dir. NA",
"Sales Dir. SE",
"Sales Director UK",
"Sales Engineering Mgr.",
"Sales Engr.",
"Sales Exec.",
"Sales Mgr.",
"Sales Northeast",
"Sales OEM",
"Sales Rep.",
"Sales Support",
"Sales VP",
"Sec'y",
"Sec'y & Treas",
"Secretary",
"Secretary General",
"Section Mgr.",
"Senior Associate",
"Senior Partner",
"Service",
"Service & Sales",
"Service & Support",
"Service Eng.",
"Service Engineer",
"Service Mgr.",
"Services Mgr.",
"SEVP",
"SEVP & CFO",
"SEVP & COO",
"SEVP Americas",
"SEVP and COO",
"SEVP Finance",
"SEVP Human Resources",
"SEVP Mktg.",
"Site Mgr.",
"Site Mgr. SE Operations",
"Social Media Dir.",
"Software Developer",
"Sr.  EVP",
"Sr. Accountant",
"Sr. Acct. Mgr.",
"Sr. Advisor",
"Sr. Analyst",
"Sr. Associate",
"Sr. Buyer",
"Sr. Consultant",
"Sr. Counsel",
"Sr. Dir.",
"Sr. Dir. Asia Bus. Dev.",
"Sr. Dir. EMEA Sales",
"Sr. Dir. HR",
"Sr. Dir. of IR",
"Sr. Dir. Operations",
"Sr. Dir. Sales",
"Sr. Dir. Sales & Mktg.",
"Sr. Editor",
"Sr. EVP",
"Sr. Exec. Dir.",
"Sr. Executive Officer",
"Sr. Financial Analyst",
"Sr. Grp. Systems Mgr.",
"Sr. HR Mgr.",
"Sr. Lawyer",
"Sr. Leasing Mgr.",
"Sr. Media Relations Mgr.",
"Sr. Mgr.",
"Sr. Mgr. HR",
"Sr. Mgr. Sales",
"Sr. Mktg. & Sales",
"Sr. Mktg. Mgr.",
"Sr. Mktg. Specialist",
"Sr. Mng. Dir.",
"Sr. Mng. Dir. HR",
"Sr. Mng. Exec.",
"Sr. Mng. Exec. Dir.",
"Sr. Mng. Officer",
"Sr. Officer",
"Sr. Principal",
"Sr. Process Engineer",
"Sr. Product Mgr",
"Sr. Project Mgr.",
"Sr. Prtn.",
"Sr. Prtn. & Mng. Dir.",
"Sr. Purchasing Mgr.",
"Sr. Rep.",
"Sr. Sales Dir.",
"Sr. Sales Engineer",
"Sr. Sales Mgr.",
"Sr. Sales Rep.",
"Sr. Trader",
"Sr.EVP & CTO",
"Studio Mgr.",
"Superintendent",
"Supervisor",
"Supply Chain Mgmt.",
"Supply Chain Mgr.",
"Support",
"Support Analyst",
"Support Tech.",
"SVP",
"SVP  Admin.",
"SVP & CAO",
"SVP & CEO",
"SVP & CFO",
"SVP & CIO",
"SVP & Controller",
"SVP & COO",
"SVP & Country Mgr.",
"SVP & CTO",
"SVP & Gen. Counsel",
"SVP & Gen. Mgr.",
"SVP & Gen. Mgr. EMEA",
"SVP & Head of Finance",
"SVP & IR",
"SVP & Mktg.",
"SVP & Mng. Dir.",
"SVP & Reg. Mgr.",
"SVP & Treasurer",
"SVP Admin.",
"SVP Americas",
"SVP Asia",
"SVP Asia Pacific",
"SVP Bus. Affairs",
"SVP Bus. Dev.",
"SVP Canada",
"SVP Communications",
"SVP Corp. Affairs",
"SVP Corp. Dev.",
"SVP Corp. Ops.",
"SVP EMEA",
"SVP Engineering",
"SVP Europe",
"SVP Fin. & CFO",
"SVP Finance",
"SVP Finance & CFO",
"SVP Global",
"SVP Global Bus. Dev.",
"SVP Global Development",
"SVP Global HR",
"SVP Global Oper.",
"SVP Global Operations",
"SVP Global Sales",
"SVP Global Sales & Mktg.",
"SVP HR",
"SVP HR & Admin",
"SVP Int'l.",
"SVP Int'l. Sales",
"SVP IR",
"SVP Leasing",
"SVP Legal",
"SVP Legal Counsel",
"SVP Marketing",
"SVP Mfg.",
"SVP Mktg.",
"SVP Mktg. & Comm.",
"SVP Mktg. & Sales",
"SVP Nat'l Sales",
"SVP Operations",
"SVP PR",
"SVP R&D",
"SVP Sales",
"SVP Sales & Mktg.",
"SVP Sales NA",
"SVP Sales/Mktg.",
"SVP Spirit Sales",
"SVP Supply Chain",
"SVP US Operations",
"SVP West Region",
"SVP Worldwide",
"SVP Worldwide Sales",
"SW Reg. Mgr.",
"Systems Admin.",
"Systems Engineer",
"Talent Officer",
"Team Leader",
"Tech Dir.",
"Tech Sales",
"Tech Sales Mgr.",
"Tech Support",
"Tech. Dir.",
"Tech. Mgr.",
"Tech. Operations Mgr",
"Tech. Plant Mgr.",
"Tech. Sales Mgr.",
"Technical Advisor",
"Technical Dir.",
"Technology & Development",
"Technology Dir.",
"Terminal Mgr.",
"Territory Mgr.",
"Trader",
"Treasurer",
"Treasurer & CFO",
"UI Developer",
"Underwriter",
"US",
"US Sales",
"US Sales Mgr.",
"VC",
"Vice CEO",
"Vice Chairman",
"Vice Chmn.",
"Vice Chmn. & CEO",
"Vice Chmn. & CFO",
"Vice Chmn. & COO",
"Vice Chmn. & GM",
"Vice Chmn. & Mng. Dir.",
"Vice Chmn. Intl.",
"Vice Gen. Mgr.",
"Vice General Mgr.",
"VP",
"VP  & Gen. Mgr.",
"VP & CAO",
"VP & CFO",
"VP & CIO",
"VP & Controller",
"VP & COO",
"VP & Country Mgr.",
"VP & CTO",
"VP & Dir. Admin.",
"VP & Dist. Mgr.",
"VP & Gen. Counsel",
"VP & Gen. Mgr.",
"VP & GM",
"VP & GM Americas",
"VP & GM Sales",
"VP & HR",
"VP & IR",
"VP & Mng. Dir.",
"VP & Operations Mgr.",
"VP & Publisher",
"VP & Sec.",
"VP & Treasurer",
"VP Admin.",
"VP Advertising",
"VP Aerospace",
"VP Americas",
"VP and CTO",
"VP Asia",
"VP Asia-Pacific & Latin America",
"VP Asian Operations",
"VP ATV's",
"VP Brand Mktg.",
"VP Bus. Dev.",
"VP Bus. Dev. Chicago",
"VP Bus. Development",
"VP Business Dev.",
"VP Canada",
"VP CFO",
"VP Comm.",
"VP Communications",
"VP Consulting Svcs",
"VP Controller",
"VP Corp. Affairs",
"VP Corp. Comm.",
"VP Corp. Finance",
"VP Corporate",
"VP Customer Service",
"VP Dev.",
"VP Development",
"VP Digital",
"VP Drug Discovery",
"VP Electric Power Div.",
"VP EMEA",
"VP Engineering",
"VP Engr.",
"VP Engr. & Operations",
"VP Europe",
"VP European Operations.",
"VP Exploration",
"VP Fin.",
"VP Fin. & Admin",
"VP Fin. & CFO",
"VP Fin. & HR",
"VP Finance",
"VP Finance & Admin.",
"VP Finance & HR",
"VP Finance Americas",
"VP Finance US",
"VP Foodservice Div.",
"VP Franchise",
"VP Gen. Mgr.",
"VP Global",
"VP Global Bus. Dev.",
"VP Global HR",
"VP Global Mktg.",
"VP Global Operations",
"VP Global R&D",
"VP Global Sales",
"VP Global Sales & Mktg.",
"VP HR",
"VP HR US",
"VP India",
"VP Int'l",
"VP Int'l Training & Devl.",
"VP Int'l.",
"VP Intl. Sales",
"VP IR",
"VP IR & Corp. Affairs",
"VP Latin America",
"VP Leasing",
"VP Legal",
"VP Logistics",
"VP Marine Div.",
"VP Marketing",
"VP Media",
"VP Media Relations",
"VP Mexico",
"VP Mfg.",
"VP Mfr.",
"VP Mktg.",
"VP Mktg. & Bus. Dev.",
"VP Mktg. & Sales",
"VP NA Sales",
"VP North America",
"VP of Dev.",
"VP Operations",
"VP Operations & COO",
"VP Operations & Customer Service",
"VP Operations & Fin.",
"VP Operations & R&D",
"VP Opers.",
"VP People",
"VP Personnel",
"VP Polymers",
"VP PR",
"VP Procurement",
"VP Product Dev.",
"VP Product Development",
"VP Product Mgmt.",
"VP Product Mgr.",
"VP Products",
"VP Project Leasing",
"VP Purchasing",
"VP R&D",
"VP R&D Bus. & Mktg.",
"VP Reg.",
"VP Reg. Mgr.",
"VP Reg. Sales",
"VP Research & Dev.",
"VP Retail",
"VP Sales",
"VP Sales & Bus. Dev.",
"VP Sales & Bus. Dev. EMEA",
"VP Sales & Mktg",
"VP Sales & Mktg.",
"VP Sales & Operations",
"VP Sales Americas",
"VP Sales APAC",
"VP Sales EMEA",
"VP Sales Mgr.",
"VP Sales Northeast",
"VP Sales/Mktg.",
"VP SE Region",
"VP Service",
"VP Services",
"VP Strategic Dev",
"VP Strategic Partnerships",
"VP Supply Chain",
"VP SW",
"VP Tech",
"VP Technology",
"VP Training Services",
"VP US",
"VP US Operations",
"VP Warehouse",
"VP Worldwide",
"VP Worldwide HR",
"VP Worldwide Mktg.",
"VP Worldwide Operations",
"VP Worldwide Sales",
"Warehouse Mgr.",
"Warehousing Dir.",
"Western Reg. Mgr.",
"Western Reg. Sales Mgr.",
"Western Region",
"World Wide CEO",
"World Wide COO",
"World Wide EVP",
"World Wide HR"]

export const countryLabelMap = { 'Afghanistan': 'Province', 'Aland Islands': 'Province', 'Albania': 'Province',
'Algeria': 'Province', 'American Samoa': 'State', 'Andorra': 'Province', 'Angola': 'Province', 'Anguilla': 'Province',
'Antarctica': 'Province', 'Antigua And Barbuda': 'Province', 'Argentina': 'State', 'Armenia': 'Province',
'Aruba': 'Province', 'Australia': 'State', 'Austria': 'Province', 'Azerbaijan': 'Province', 'Bahamas': 'Island',
'Bahrain': 'Province', 'Bangladesh': 'Province', 'Barbados': 'Province', 'Belarus': 'Province', 'Belgium': 'Province',
'Belize': 'Province', 'Benin': 'Province', 'Bermuda': 'Province', 'Bhutan': 'Province', 'Bolivia': 'Province',
'Bosnia And Herzegovina': 'Province', 'Botswana': 'Province', 'Bouvet Island': 'Province', 'Brazil': 'State',
'British Indian Ocean Territory': 'Province', 'Brunei Darussalam': 'Province', 'Bulgaria': 'Province',
'Burkina Faso': 'Province', 'Burundi': 'Province', 'Cambodia': 'Province', 'Cameroon': 'Province', 'Canada': 'Province',
'Cape Verde': 'Island', 'Cayman Islands': 'Island', 'Central African Republic': 'Province', 'Chad': 'Province',
'Chile': 'State', 'China': 'Province', 'Christmas Island': 'Province', 'Cocos (Keeling) Islands': 'Province',
'Colombia': 'Department', 'Comoros': 'Province', 'Congo': 'Province', 'Congo, Democratic Republic': 'Province',
'Cook Islands': 'Province', 'Costa Rica': 'Province', 'Cote D\'Ivoire': 'Province', 'Croatia': 'Province',
'Cuba': 'Province', 'Cyprus': 'Province', 'Czech Republic': 'Province', 'Denmark': 'Province', 'Djibouti': 'Province',
'Dominica': 'Province', 'Dominican Republic': 'Province', 'Ecuador': 'Province', 'Egypt': 'Governorate',
'El Salvador': 'Province', 'Equatorial Guinea': 'Province', 'Eritrea': 'Province', 'Estonia': 'County',
'Ethiopia': 'Province', 'Falkland Islands (Malvinas)': 'Province', 'Faroe Islands': 'Province', 'Fiji': 'Province',
'Finland': 'Province', 'France': 'Province', 'French Guiana': 'Province', 'French Polynesia': 'Island',
'French Southern Territories': 'Province', 'Gabon': 'Province', 'Gambia': 'Province', 'Georgia': 'Province',
'Germany': 'Province', 'Ghana': 'Province', 'Gibraltar': 'Province', 'Greece': 'Province', 'Greenland': 'Province',
'Grenada': 'Province', 'Guadeloupe': 'Province', 'Guam': 'State', 'Guatemala': 'Province', 'Guernsey': 'Province',
'Guinea': 'Province', 'Guinea-Bissau': 'Province', 'Guyana': 'Province', 'Haiti': 'Province',
'Heard Island & Mcdonald Islands': 'Province', 'Holy See (Vatican City State)': 'Province', 'Honduras': 'Province',
'Hong Kong': 'Area', 'Hungary': 'Province', 'Iceland': 'Province', 'India': 'State', 'Indonesia': 'Province',
'Iran, Islamic Republic Of': 'Province', 'Iraq': 'Province', 'Ireland': 'County', 'Isle Of Man': 'Province',
'Israel': 'Province', 'Italy': 'Province', 'Jamaica': 'Parish', 'Japan': 'Prefecture', 'Jersey': 'Province',
'Jordan': 'Province', 'Kazakhstan': 'Region', 'Kenya': 'Province', 'Kiribati': 'Island', 'Korea': 'Province',
'Kuwait': 'Province', 'Kyrgyzstan': 'Province', 'Lao People\'s Democratic Republic': 'Province', 'Latvia': 'Municipality',
'Lebanon': 'Province', 'Lesotho': 'Province', 'Liberia': 'Province', 'Libyan Arab Jamahiriya': 'Province',
'Liechtenstein': 'Province', 'Lithuania': 'County', 'Luxembourg': 'Province', 'Macao': 'Province', 'Macedonia': 'Province',
'Madagascar': 'Province', 'Malawi': 'Province', 'Malaysia': 'State', 'Maldives': 'Province', 'Mali': 'Province',
'Malta': 'Province', 'Marshall Islands': 'State', 'Martinique': 'Province', 'Mauritania': 'Province',
'Mauritius': 'Province', 'Mayotte': 'Province', 'Mexico': 'State', 'Micronesia, Federated States Of': 'State',
'Moldova': 'Province', 'Monaco': 'Province', 'Mongolia': 'Province', 'Montenegro': 'Province', 'Montserrat': 'Province',
'Morocco': 'Province', 'Mozambique': 'Province', 'Myanmar': 'Province', 'Namibia': 'Province', 'Nauru': 'District',
'Nepal': 'Province', 'Netherlands': 'Province', 'Netherlands Antilles': 'Province', 'New Caledonia': 'Province',
'New Zealand': 'Province', 'Nicaragua': 'Department', 'Niger': 'Province', 'Nigeria': 'State', 'Niue': 'Province',
'Norfolk Island': 'Province', 'Northern Mariana Islands': 'State', 'Norway': 'Province', 'Oman': 'Province',
'Pakistan': 'Province', 'Palau': 'State', 'Palestinian Territory, Occupied': 'Province', 'Panama': 'Province',
'Papua New Guinea': 'Province', 'Paraguay': 'Province', 'Peru': 'Department', 'Philippines': 'Province',
'Pitcairn': 'Province', 'Poland': 'Province', 'Portugal': 'Province', 'Puerto Rico': 'Province', 'Qatar': 'Province',
'Reunion': 'Province', 'Romania': 'Province', 'Russian Federation': 'Province', 'Rwanda': 'Province',
'Saint Barthelemy': 'Province', 'Saint Helena': 'Province', 'Saint Kitts And Nevis': 'Island', 'Saint Lucia': 'Province',
'Saint Martin': 'Province', 'Saint Pierre And Miquelon': 'Province', 'Saint Vincent And Grenadines': 'Province',
'Samoa': 'Province', 'San Marino': 'Province', 'Sao Tome And Principe': 'Province', 'Saudi Arabia': 'Province',
'Senegal': 'Province', 'Serbia': 'Province', 'Seychelles': 'Island', 'Sierra Leone': 'Province', 'Singapore': 'Province',
'Slovakia': 'Province', 'Slovenia': 'Province', 'Solomon Islands': 'Province', 'Somalia': 'Province',
'South Africa': 'Province', 'South Georgia And Sandwich Isl.': 'Province', 'Spain': 'Province', 'Sri Lanka': 'Province',
'Sudan': 'Province', 'Suriname': 'Province', 'Svalbard And Jan Mayen': 'Province', 'Swaziland': 'Province',
'Sweden': 'Province', 'Switzerland': 'Province', 'Syrian Arab Republic': 'Province', 'Taiwan': 'Province',
'Tajikistan': 'Province', 'Tanzania': 'Province', 'Thailand': 'Province', 'Timor-Leste': 'Province', 'Togo': 'Province',
'Tokelau': 'Province', 'Tonga': 'Province', 'Trinidad And Tobago': 'Province', 'Tunisia': 'Province', 'Turkey': 'Province',
'Turkmenistan': 'Province', 'Turks And Caicos Islands': 'Province', 'Tuvalu': 'Island', 'Uganda': 'Province',
'Ukraine': 'Region', 'United Arab Emirates': 'Emirate', 'United Kingdom': 'County', 'United States': 'State',
'United States Outlying Islands': 'State', 'Uruguay': 'Province', 'Uzbekistan': 'Province', 'Vanuatu': 'Province',
'Venezuela': 'Province', 'Viet Nam': 'Province', 'Virgin Islands, British': 'Province', 'Virgin Islands, U.S.': 'State',
'Wallis And Futuna': 'Province', 'Western Sahara': 'Province', 'Yemen': 'Province', 'Zambia': 'Province',
'Zimbabwe': 'Province' };
export const hidePostalCodeCountries = [ 'Antarctica', 'Antigua And Barbuda', 'Aruba', 'Bahamas',
'Belize', 'Benin', 'Bolivia', 'Botswana', 'Bouvet Island', 'Burkina Faso', 'Burundi', 'Cameroon',
'Central African Republic', 'Chad', 'Comoros', 'Congo', 'Congo, Democratic Republic', 'Cook Islands',
'Cote D\'Ivoire', 'Djibouti', 'Dominica', 'Equatorial Guinea', 'Eritrea', 'Fiji',
'French Southern Territories', 'Gabon', 'Gambia', 'Ghana', 'Grenada', 'Guyana', 'Hong Kong',
'Kiribati', 'Libyan Arab Jamahiriya', 'Macao', 'Malawi', 'Mali', 'Mauritania', 'Montserrat',
'Namibia', 'Nauru', 'Netherlands Antilles', 'Niue', 'Palestinian Territory, Occupied', 'Qatar',
'Rwanda', 'Saint Kitts And Nevis', 'Saint Lucia', 'Samoa', 'Sao Tome And Principe', 'Seychelles',
'Sierra Leone', 'Solomon Islands', 'Suriname', 'Syrian Arab Republic', 'Timor-Leste', 'Togo',
'Tokelau', 'Tonga', 'Trinidad And Tobago', 'Tuvalu', 'Uganda', 'United Arab Emirates', 'Vanuatu', 'Yemen',
'Zimbabwe' ];
export const postalCodeLabelMap = { 'Afghanistan': 'Postal code', 'Aland Islands': 'Postal code',
'Albania': 'Postal code', 'Algeria': 'Postal code', 'American Samoa': 'ZIP code', 'Andorra': 'Postal code',
'Angola': 'Postal code', 'Anguilla': 'Postal code', 'Antarctica': 'Postal code', 'Antigua And Barbuda': 'Postal code',
'Argentina': 'Postal code', 'Armenia': 'Postal code', 'Aruba': 'Postal code', 'Australia': 'Postcode',
'Austria': 'Postal code', 'Azerbaijan': 'Postal code', 'Bahamas': 'Postal code', 'Bahrain': 'Postal code',
'Bangladesh': 'Postal code', 'Barbados': 'Postal code', 'Belarus': 'Postal code', 'Belgium': 'Postal code',
'Belize': 'Postal code', 'Benin': 'Postal code', 'Bermuda': 'Postal code', 'Bhutan': 'Postal code',
'Bolivia': 'Postal code', 'Bosnia And Herzegovina': 'Postal code', 'Botswana': 'Postal code',
'Bouvet Island': 'Postal code', 'Brazil': 'Postal code', 'British Indian Ocean Territory': 'Postal code',
'Brunei Darussalam': 'Postal code', 'Bulgaria': 'Postal code', 'Burkina Faso': 'Postal code',
'Burundi': 'Postal code', 'Cambodia': 'Postal code', 'Cameroon': 'Postal code', 'Canada': 'Postal code',
'Cape Verde': 'Postal code', 'Cayman Islands': 'Postal code', 'Central African Republic': 'Postal code',
'Chad': 'Postal code', 'Chile': 'Postal code', 'China': 'Postal code', 'Christmas Island': 'Postal code',
'Cocos (Keeling) Islands': 'Postal code', 'Colombia': 'Postal code', 'Comoros': 'Postal code', 'Congo': 'Postal code',
'Congo, Democratic Republic': 'Postal code', 'Cook Islands': 'Postal code', 'Costa Rica': 'Postal code',
'Cote D\'Ivoire': 'Postal code', 'Croatia': 'Postal code', 'Cuba': 'Postal code', 'Cyprus': 'Postal code',
'Czech Republic': 'Postal code', 'Denmark': 'Postal code', 'Djibouti': 'Postal code', 'Dominica': 'Postal code',
'Dominican Republic': 'Postal code', 'Ecuador': 'Postal code', 'Egypt': 'Postal code', 'El Salvador': 'Postal code',
'Equatorial Guinea': 'Postal code', 'Eritrea': 'Postal code', 'Estonia': 'Postal code', 'Ethiopia': 'Postal code',
'Falkland Islands (Malvinas)': 'Postal code', 'Faroe Islands': 'Postal code', 'Fiji': 'Postal code',
'Finland': 'Postal code', 'France': 'Postal code', 'French Guiana': 'Postal code', 'French Polynesia': 'Postal code',
'French Southern Territories': 'Postal code', 'Gabon': 'Postal code', 'Gambia': 'Postal code', 'Georgia': 'Postal code',
'Germany': 'Postal code', 'Ghana': 'Postal code', 'Gibraltar': 'Postal code', 'Greece': 'Postal code',
'Greenland': 'Postal code', 'Grenada': 'Postal code', 'Guadeloupe': 'Postal code', 'Guam': 'ZIP code',
'Guatemala': 'Postal code', 'Guernsey': 'Postal code', 'Guinea': 'Postal code', 'Guinea-Bissau': 'Postal code',
'Guyana': 'Postal code', 'Haiti': 'Postal code', 'Heard Island & Mcdonald Islands': 'Postal code',
'Holy See (Vatican City State)': 'Postal code', 'Honduras': 'Postal code', 'Hong Kong': 'Postal code',
'Hungary': 'Postal code', 'Iceland': 'Postal code', 'India': 'PIN code', 'Indonesia': 'Postal code',
'Iran, Islamic Republic Of': 'Postal code', 'Iraq': 'Postal code', 'Ireland': 'Eircode', 'Isle Of Man': 'Postal code',
'Israel': 'Postal code', 'Italy': 'Postal code', 'Jamaica': 'Postal code', 'Japan': 'Postal code', 'Jersey': 'Postal code',
'Jordan': 'Postal code', 'Kazakhstan': 'Postal code', 'Kenya': 'Postal code', 'Kiribati': 'Postal code',
'Korea': 'Postal code', 'Kuwait': 'Postal code', 'Kyrgyzstan': 'Postal code',
'Lao People\'s Democratic Republic': 'Postal code', 'Latvia': 'Postal code', 'Lebanon': 'Postal code',
'Lesotho': 'Postal code', 'Liberia': 'Postal code', 'Libyan Arab Jamahiriya': 'Postal code', 'Liechtenstein': 'Postal code',
'Lithuania': 'Postal code', 'Luxembourg': 'Postal code', 'Macao': 'Postal code', 'Macedonia': 'Postal code',
'Madagascar': 'Postal code', 'Malawi': 'Postal code', 'Malaysia': 'Postal code', 'Maldives': 'Postal code',
'Mali': 'Postal code', 'Malta': 'Postal code', 'Marshall Islands': 'ZIP code', 'Martinique': 'Postal code',
'Mauritania': 'Postal code', 'Mauritius': 'Postal code', 'Mayotte': 'Postal code', 'Mexico': 'Postal code',
'Micronesia, Federated States Of': 'ZIP code', 'Moldova': 'Postal code', 'Monaco': 'Postal code', 'Mongolia': 'Postal code',
'Montenegro': 'Postal code', 'Montserrat': 'Postal code', 'Morocco': 'Postal code', 'Mozambique': 'Postal code',
'Myanmar': 'Postal code', 'Namibia': 'Postal code', 'Nauru': 'Postal code', 'Nepal': 'Postal code',
'Netherlands': 'Postal code', 'Netherlands Antilles': 'Postal code', 'New Caledonia': 'Postal code',
'New Zealand': 'Postcode', 'Nicaragua': 'Postal code', 'Niger': 'Postal code', 'Nigeria': 'Postal code',
'Niue': 'Postal code', 'Norfolk Island': 'Postal code', 'Northern Mariana Islands': 'ZIP code', 'Norway': 'Postal code',
'Oman': 'Postal code', 'Pakistan': 'Postal code', 'Palau': 'ZIP code', 'Palestinian Territory, Occupied': 'Postal code',
'Panama': 'Postal code', 'Papua New Guinea': 'Postal code', 'Paraguay': 'Postal code', 'Peru': 'Postal code',
'Philippines': 'Postal code', 'Pitcairn': 'Postal code', 'Poland': 'Postal code', 'Portugal': 'Postal code',
'Puerto Rico': 'ZIP code', 'Qatar': 'Postal code', 'Reunion': 'Postal code', 'Romania': 'Postal code',
'Russian Federation': 'Postal code', 'Rwanda': 'Postal code', 'Saint Barthelemy': 'Postal code',
'Saint Helena': 'Postal code', 'Saint Kitts And Nevis': 'Postal code', 'Saint Lucia': 'Postal code',
'Saint Martin': 'Postal code', 'Saint Pierre And Miquelon': 'Postal code', 'Saint Vincent And Grenadines': 'Postal code',
'Samoa': 'Postal code', 'San Marino': 'Postal code', 'Sao Tome And Principe': 'Postal code', 'Saudi Arabia': 'Postal code',
'Senegal': 'Postal code', 'Serbia': 'Postal code', 'Seychelles': 'Postal code', 'Sierra Leone': 'Postal code',
'Singapore': 'Postal code', 'Slovakia': 'Postal code', 'Slovenia': 'Postal code', 'Solomon Islands': 'Postal code',
'Somalia': 'Postal code', 'South Africa': 'Postal code', 'South Georgia And Sandwich Isl.': 'Postal code',
'Spain': 'Postal code', 'Sri Lanka': 'Postal code', 'Sudan': 'Postal code', 'Suriname': 'Postal code',
'Svalbard And Jan Mayen': 'Postal code', 'Swaziland': 'Postal code', 'Sweden': 'Postal code', 'Switzerland': 'Postal code',
'Syrian Arab Republic': 'Postal code', 'Taiwan': 'Postal code', 'Tajikistan': 'Postal code', 'Tanzania': 'Postal code',
'Thailand': 'Postal code', 'Timor-Leste': 'Postal code', 'Togo': 'Postal code', 'Tokelau': 'Postal code',
'Tonga': 'Postal code', 'Trinidad And Tobago': 'Postal code', 'Tunisia': 'Postal code', 'Turkey': 'Postal code',
'Turkmenistan': 'Postal code', 'Turks And Caicos Islands': 'Postal code', 'Tuvalu': 'Postal code', 'Uganda': 'Postal code',
'Ukraine': 'Postal code', 'United Arab Emirates': 'Postal code', 'United Kingdom': 'Postcode', 'United States': 'ZIP code',
'United States Outlying Islands': 'ZIP code', 'Uruguay': 'Postal code', 'Uzbekistan': 'Postal code', 'Vanuatu': 'Postal code',
'Venezuela': 'Postal code', 'Viet Nam': 'Postal code', 'Virgin Islands, British': 'Postal code',
'Virgin Islands, U.S.': 'ZIP code', 'Wallis And Futuna': 'Postal code', 'Western Sahara': 'Postal code',
'Yemen': 'Postal code', 'Zambia': 'Postal code', 'Zimbabwe': 'Postal code' };