import React, { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CompanyDataService from '../services/CompanyDataService';
import { 
    Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, 
    TableRow, Paper, TablePagination, TextField, Typography, Alert, MenuItem,
    CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { subsidiaryTypes, listOfTitles, stateNameToCode, gmapsApiKey } from '../helper';
import { AUTH_TOKEN } from '../helper';
import { findMatchingState } from './state-matching-utils';

const ResizableTableCell = styled(TableCell)(({ width }) => ({
    position: 'relative',
    width: width || 'auto',
    minWidth: width || 150,
    padding: '16px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

const ResizeHandle = styled('div')({
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: '4px',
    cursor: 'col-resize',
    backgroundColor: 'transparent',
    '&:hover': {
        backgroundColor: '#999'
    },
    '&.dragging': {
        backgroundColor: '#999',
        width: '2px'
    }
});

const StyledTableContainer = styled(TableContainer)({
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    '& table': {
        tableLayout: 'fixed'
    }
});

const BulkImportSubsidiaries = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [subsidiaryData, setSubsidiaryData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [countryStatesMap, setCountryStatesMap] = useState({});
    const [invalidRows, setInvalidRows] = useState([]);
    const [validRows, setValidRows] = useState([]);
    const [rowsWithErrors, setRowsWithErrors] = useState([]);
    const [resizing, setResizing] = useState(null);
    const [columnWidths, setColumnWidths] = useState({});
    const [initialWidths, setInitialWidths] = useState({});
    const tableRef = useRef(null);
    const [processingAddresses, setProcessingAddresses] = useState(false);
    const [processedCount, setProcessedCount] = useState(0);
    const [totalToProcess, setTotalToProcess] = useState(0);
    const expectedColumns = [
        'Company', 'Address', 'Address2', 'City', 'StateProvince', 'PostalCode',
        'Country', 'Email', 'Telephone', 'Fax', 'Website', 'SubsidiaryType',
        'Contact1', 'Contact1Title', 'Contact2', 'Contact2Title', 'Latitude', 'Longitude'
    ];
    const [countryPrefixes, setCountryPrefixes] = useState([]);

    useEffect(() => {
        const fetchCountryPrefixes = async () => {
            try {
                const response = await CompanyDataService.getAllCountryPrefixes(); 
                setCountryPrefixes(response.data);
            } catch (error) {
                console.error("Failed to fetch country prefixes:", error);
            }
        };
    
        fetchCountryPrefixes();
    }, []);

    useEffect(() => {
        const fetchCountriesAndStates = async () => {
            try {
                const countriesResponse = await CompanyDataService.getAllCountries();
                setCountries(countriesResponse.data);

                const statesResponse = await CompanyDataService.getAllStates();
                setCountryStatesMap(statesResponse.data);
            } catch (error) {
                console.error("Failed to fetch countries and states:", error);
            }
        };

        fetchCountriesAndStates();
    }, []);

    const validateRow = (row) => {
        const errors = [];
    
        const origLat = row.Latitude;
        const origLong = row.Longitude;
    
        if (row.Country && !countries.includes(row.Country)) {
            errors.push('Country');
        }
    
        if (row.StateProvince && row.Country) {
            const requiredStateProvinceCountries = [
                'Venezuela', 'United States', 'Ukraine', 'Taiwan', 'Turkey', 'Russia', 
                'Philippines', 'Peru', 'Malaysia', 'Mexico', 'Kazakhstan', 'South Korea', 
                'Japan', 'Jamaica', 'Italy', 'India', 'Ireland', 'Indonesia', 'Hong Kong', 
                'Spain', 'Egypt', 'Estonia', 'Colombia', 'China', 'Chile', 'Canada', 
                'Brazil', 'Australia', 'Argentina', 'United Arab Emirates', 'United Kingdom'
            ];
    
            if (row.Country) {
                const isRequiredCountry = requiredStateProvinceCountries.includes(row.Country);
                const validStates = countryStatesMap[row.Country] || [];
    
                // Case 1: Country requires state/province validation
                if (isRequiredCountry) {
                    // Check if state/province is missing when required
                    if (!row.StateProvince && validStates.length > 0) {
                        errors.push('StateProvince');
                    }
                    // Validate state/province if provided
                    else if (row.StateProvince && validStates.length > 0) {
                        const matchingState = findMatchingState(row.StateProvince, validStates);
                        if (!matchingState) {
                            errors.push('StateProvince');
                        } else if (matchingState !== row.StateProvince) {
                            row.StateProvince = matchingState;
                        }
                    }
                }
                // Case 2: Country doesn't require state/province validation
                else if (row.StateProvince && validStates.length > 0) {
                    // Only validate if a state/province is provided and valid states exist
                    // Make sure to also check for empty row.StateProvince
                    if (row.StateProvince.trim() !== '') {
                        const matchingState = findMatchingState(row.StateProvince, validStates);
                        if (!matchingState) {
                            row.StateProvince = '';
                        } else if (matchingState !== row.StateProvince) {
                            row.StateProvince = matchingState;
                        }
                    }
                }
            }
        }
    
        if (row.SubsidiaryType && !subsidiaryTypes.includes(row.SubsidiaryType)) {
            errors.push('SubsidiaryType');
        }
    
        if (row.Contact1Title && row.Contact1Title.trim() !== '') {
            if (!listOfTitles.includes(row.Contact1Title)) {
                errors.push('Contact1Title');
            }
        }
        
        if (row.Contact2Title && row.Contact2Title.trim() !== '') {
            if (!listOfTitles.includes(row.Contact2Title)) {
                errors.push('Contact2Title');
            }
        }
    
        if (origLat !== row.Latitude || origLong !== row.Longitude) {
            console.warn('Geocoding data changed during validation:', {
                rowIndex: row.rowIndex,
                original: { lat: origLat, long: origLong },
                current: { lat: row.Latitude, long: row.Longitude }
            });
        }
    
        return errors;
    };

    const calculateColumnWidths = (data) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = '14px Roboto';

        const widths = {};
        expectedColumns.forEach(column => {
            let maxWidth = context.measureText(column).width + 40;

            data.forEach(row => {
                const cellContent = row[column]?.toString() || '';
                const cellWidth = context.measureText(cellContent).width + 40;
                maxWidth = Math.max(maxWidth, cellWidth);
            });

            widths[column] = Math.max(maxWidth, 150);
        });

        return widths;
    };

    const startResizing = (e, column) => {
        e.preventDefault();
        const startX = e.pageX;
        const startWidth = columnWidths[column] || initialWidths[column] || 150;

        const handleMouseMove = (moveEvent) => {
            const diff = moveEvent.pageX - startX;
            const newWidth = Math.max(150, startWidth + diff);
            
            setColumnWidths(prev => ({
                ...prev,
                [column]: newWidth
            }));
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const renderHeaderCell = (column) => (
        <ResizableTableCell
            key={column}
            width={columnWidths[column] || initialWidths[column]}
        >
            {column}
            <ResizeHandle
                onMouseDown={(e) => startResizing(e, column)}
            />
        </ResizableTableCell>
    );

    const renderDataCell = (row, column) => (
        <ResizableTableCell
            key={column}
            width={columnWidths[column] || initialWidths[column]}
            style={{
                backgroundColor: row.validationErrors?.includes(column) ? '#ffebee' : 'inherit'
            }}
        >
            <TextField
                value={row[column] || ''}
                onChange={(e) => handleCellChange(row.rowIndex, column, e.target.value)}
                variant="standard"
                size="small"
                fullWidth
                disabled={loading || column === 'Latitude' || column === 'Longitude'}
                error={row.validationErrors?.includes(column)}
                select={column === 'Country' || 
                       (column === 'StateProvince' && row.Country && countryStatesMap[row.Country]?.length > 0) ||
                       column === 'SubsidiaryType' ||
                       column === 'Contact1Title' ||
                       column === 'Contact2Title'}
            >
                {column === 'Country' && countries.map(country => (
                    <MenuItem key={country} value={country}>{country}</MenuItem>
                ))}
                {column === 'StateProvince' && row.Country && countryStatesMap[row.Country]?.map(state => (
                    <MenuItem key={state} value={state}>{state}</MenuItem>
                ))}
                {column === 'SubsidiaryType' && subsidiaryTypes.map(type => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
                {(column === 'Contact1Title' || column === 'Contact2Title') && listOfTitles.map(title => (
                    <MenuItem key={title} value={title}>{title || '- None -'}</MenuItem>
                ))}
            </TextField>
        </ResizableTableCell>
    );

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const text = e.target.result;
                const lines = text.split('\n');
                const headers = lines[0].trim().split(',');

                const missingColumns = expectedColumns
                    .filter(col => !headers.includes(col))
                    .filter(col => col !== 'Latitude' && col !== 'Longitude');

                if (missingColumns.length > 0) {
                    setError(`Missing required columns: ${missingColumns.join(', ')}`);
                    return;
                }

                const parseCSVLine = (line) => {
                    const result = [];
                    let current = '';
                    let inQuotes = false;
                
                    for (let i = 0; i < line.length; i++) {
                        const char = line[i];
                        if (char === '"') {
                            if (inQuotes && line[i + 1] === '"') {
                                current += char;
                                i++;
                            } else {
                                inQuotes = !inQuotes;
                            }
                        } else if (char === ',' && !inQuotes) {
                            result.push(current.trim());
                            current = '';
                        } else {
                            current += char;
                        }
                    }
                    result.push(current.trim());
                    return result;
                };

                const allRows = lines.slice(1)
                    .filter(line => line.trim())
                    .map((line, index) => {
                        const values = parseCSVLine(line);
                        const row = {};
                        headers.forEach((header, i) => {
                            row[header] = values[i] || '';
                        });
                        if (row.Country) {
                            const countryPrefix = countryPrefixes.find(prefix => prefix.Country === row.Country);
                            if (countryPrefix) {
                                row.CountryPrefix = countryPrefix.CountryPrefix;
                            }
                        }
                        row.rowIndex = index + 1;
                        row.validationErrors = validateRow(row);
                        return row;
                    });

                // Separate valid and invalid rows
                const valid = allRows.filter(row => !row.validationErrors.length);
                const invalid = allRows.filter(row => row.validationErrors.length > 0);

                setValidRows(valid);
                setRowsWithErrors(invalid);
                setSubsidiaryData([...valid, ...invalid]); // Keep full data for submission

                const widths = calculateColumnWidths([...valid, ...invalid]);
                setInitialWidths(widths);
                setColumnWidths(widths);

                await processAddresses([...valid, ...invalid]);
            };
            reader.readAsText(file);
        }
    };

    const processAddresses = async (data) => {
        setProcessingAddresses(true);
        setTotalToProcess(data.length);
        setProcessedCount(0);

        const updatedData = [...data];

        for (let i = 0; i < updatedData.length; i++) {
            const row = updatedData[i];
            
            // First handle geocoding
            if (row.Address && row.City && (!row.Latitude || !row.Longitude)) {
                try {
                    await new Promise(resolve => setTimeout(resolve, 20));
                    const fullAddress = `${row.Address} ${row.Address2 || ''} ${row.City} ${row.StateProvince || ''} ${row.PostalCode || ''} ${row.Country}`;
                    const geocoder = new window.google.maps.Geocoder();
                    
                    const result = await new Promise((resolve, reject) => {
                        geocoder.geocode({ address: fullAddress }, (results, status) => {
                            if (status === 'OK' && results && results[0]) {
                                resolve(results[0].geometry.location);
                            } else {
                                reject(new Error(`Geocoding failed with status: ${status}`));
                            }
                        });
                    });

                    updatedData[i].Latitude = result.lat().toString();
                    updatedData[i].Longitude = result.lng().toString();
                } catch (error) {
                    console.error('Geocoding failed for row:', i, error);
                }
            }

            // Then handle ZIP+4 lookup for US addresses
            if (row.Country === 'United States' && row.Address && row.City && row.StateProvince) {
                try {
                    const stateCode = stateNameToCode[row.StateProvince] || row.StateProvince;
                    const [zip] = (row.PostalCode || '').split('-');

                    const address = {
                        street1: row.Address,
                        street2: row.Address2 || '',
                        city: row.City,
                        state: stateCode,
                        zip: zip
                    };

                    const response = await CompanyDataService.lookupZipPlus4(address);

                    if (!response.data.error && response.data.address) {
                        const { ZIPCode, ZIPPlus4 } = response.data.address;
                        updatedData[i].PostalCode = ZIPPlus4 ? `${ZIPCode}-${ZIPPlus4}` : ZIPCode;
                    }
                } catch (error) {
                    console.error('ZIP+4 lookup failed for row:', i, error);
                }
            }

            setProcessedCount(i + 1);
        }

        // Separate valid and invalid rows again after processing
        const valid = updatedData.filter(row => !row.validationErrors.length);
        const invalid = updatedData.filter(row => row.validationErrors.length > 0);

        setValidRows(valid);
        setRowsWithErrors(invalid);
        setSubsidiaryData(updatedData);
        setProcessingAddresses(false);
    };

    const handleCellChange = (rowIndex, column, value) => {
        const updatedData = subsidiaryData.map(row => {
            if (row.rowIndex === rowIndex) {
                const updatedRow = {
                    ...row,
                    [column]: value
                };
                
                if (column === 'Country') {
                    const countryPrefix = countryPrefixes.find(prefix => prefix.Country === value);
                    if (countryPrefix) {
                        updatedRow.CountryPrefix = countryPrefix.CountryPrefix;
                    }
                }
                
                updatedRow.validationErrors = validateRow(updatedRow);
                
                return updatedRow;
            }
            return row;
        });

        setSubsidiaryData(updatedData);

        // Re-separate valid and invalid rows
        const valid = updatedData.filter(row => !row.validationErrors.length);
        const invalid = updatedData.filter(row => row.validationErrors.length > 0);

        setValidRows(valid);
        setRowsWithErrors(invalid);
    };

    const generateBlankCSV = () => {
        const csvContent = expectedColumns.join(',') + '\n';
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'subsidiary_import_template.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const handleReset = () => {
        setSubsidiaryData([]);
        setValidRows([]);
        setRowsWithErrors([]);
        setError('');
        setSuccess('');
        setInvalidRows([]);
        setProcessedCount(0);
        setTotalToProcess(0);
        setProcessingAddresses(false);
    };

    const renderValidRows = () => {
        if (!validRows.length) return null;

        return (
            <div className="valid-rows-container">
                <h3>{validRows.length} Valid Rows</h3>
                <div className="valid-rows-list" style={{ 
                    maxHeight: '300px', 
                    overflow: 'auto',
                    border: '1px solid #e0e0e0',
                    borderRadius: '4px',
                    marginBottom: '20px'
                }}>
                    {validRows.map((row, index) => (
                        <div key={row.rowIndex} className="valid-row" style={{
                            padding: '8px',
                            borderBottom: '1px solid #eee',
                            backgroundColor: index % 2 ? '#f9f9f9' : 'white',
                            fontSize: '0.9rem',
                            lineHeight: '1.4'
                        }}>
                            <strong>{row.Company}</strong>
                            <span style={{ color: '#666' }}> | </span>
                            {row.Address}
                            {row.Address2 && `, ${row.Address2}`}
                            {`, ${row.City}`}
                            {row.StateProvince && `, ${row.StateProvince}`}
                            {row.PostalCode && ` ${row.PostalCode}`}
                            {`, ${row.Country}`}
                            {row.SubsidiaryType && (
                                <>
                                    <span style={{ color: '#666' }}> | Type: </span>
                                    {row.SubsidiaryType}
                                </>
                            )}
                            {row.Contact1 && (
                                <>
                                    <span style={{ color: '#666' }}> | Contact: </span>
                                    {row.Contact1}
                                    {row.Contact1Title && ` (${row.Contact1Title})`}
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderEditableRows = () => {
        if (!rowsWithErrors.length) return null;

        return (
            <>
                <h3 style={{ color: '#d32f2f' }}>{rowsWithErrors.length} Rows Needing Attention</h3>
                <StyledTableContainer component={Paper} ref={tableRef}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell width={70}>Row</TableCell>
                                {expectedColumns.map(column => renderHeaderCell(column))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rowsWithErrors
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <TableRow key={row.rowIndex}>
                                        <TableCell width={70}>{row.rowIndex}</TableCell>
                                        {expectedColumns.map(column => renderDataCell(row, column))}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </StyledTableContainer>

                {rowsWithErrors.length > rowsPerPage && (
                    <TablePagination
                        component="div"
                        count={rowsWithErrors.length}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={(event) => {
                            setRowsPerPage(parseInt(event.target.value, 10));
                            setPage(0);
                        }}
                        rowsPerPageOptions={[10, 50, 100, 200]}
                    />
                )}
            </>
        );
    };

    const handleSaveAll = async () => {
        if (rowsWithErrors.length > 0) {
            setError('Cannot save while there are validation errors. Please correct the highlighted cells.');
            return;
        }

        setLoading(true);
        setError('');
        const user = JSON.parse(localStorage.getItem(AUTH_TOKEN));
        const timestamp = new Date().toISOString().split('T')[0];

        try {
            for (const subsidiary of subsidiaryData) {
                const parentIdField = parseInt(id) > 10000000 ? 'DraftParentID' : 'ParentNID';
                const submissionData = {
                    ...subsidiary,
                    [parentIdField]: id,
                    Status: 'Draft',
                    History: `${timestamp} ${user.user_display_name} created subsidiary via bulk import\n`
                };

                await CompanyDataService.create(submissionData);
            }

            setSuccess('All subsidiaries have been imported successfully');
            setLoading(false);
            setTimeout(() => {
                navigate(`/company/${id}/subsidiaries`);
            }, 2000);
        } catch (err) {
            setError('An unexpected error occurred during import');
            setLoading(false);
        }
    };

    return (
        <Wrapper apiKey={gmapsApiKey}>
            <Box p={3}>
                <Typography variant="h4" gutterBottom>
                    Bulk Import Subsidiaries
                </Typography>

                {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
                {processingAddresses && (
                    <Alert severity="info" sx={{ mb: 2 }}>
                        Processing addresses: {processedCount} of {totalToProcess}
                        <CircularProgress size={20} sx={{ ml: 2 }} />
                    </Alert>
                )}

                <Box mb={2} display="flex" gap={2}>
                    <Button
                        variant="contained"
                        component="label"
                        disabled={loading}
                    >
                        Upload CSV File
                        <input
                            type="file"
                            hidden
                            accept=".csv"
                            onChange={handleFileUpload}
                        />
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={generateBlankCSV}
                        disabled={loading}
                    >
                        Download Blank Template
                    </Button>
                </Box>

                {subsidiaryData.length > 0 && (
                    <>
                        {renderValidRows()}
                        {renderEditableRows()}

                        <Box mt={2} display="flex" gap={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSaveAll}
                                disabled={loading || rowsWithErrors.length > 0}
                            >
                                {loading ? 'Saving...' : `Save All ${subsidiaryData.length} Subsidiaries`}
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleReset}
                                disabled={loading}
                            >
                                Reset
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Wrapper>
    );
};

export default BulkImportSubsidiaries;